import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NewsFeedService } from 'src/app/services/news-feed/news-feed.service';
import { News } from 'src/app/models/news-feed/news';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { compressImage } from 'src/app/utils/tools.utils';



@Component({
  selector: 'app-add-news-feed',
  templateUrl: './add-news-feed.component.html',
  styleUrls: ['./add-news-feed.component.css']
})

export class NewsModalComponent implements OnInit {
  newsForm: FormGroup;
  mode: 'create' | 'edit';
  news: News;
  image: string = '';

  toolbarCkeditor = {
    toolbar: [
      ["Bold", "Italic", "Underline", "Strike"],
      ["TextColor", "BGColor"],
      [
        "NumberedList",
        "BulletedList",
        "-",
        "Blockquote",
        "-",
        "JustifyLeft",
        "JustifyCenter",
        "JustifyRight",
        "JustifyBlock",
      ],
      ["Link", "Unlink"],
      ["Image", "Table", "Smiley"],
      ["Undo", "Redo"],
    ],
  };

  constructor(
    public dialogRef: MatDialogRef<NewsModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private newsFeedService: NewsFeedService
  ) {
    this.mode = data.mode;
    this.news = data.news || {};
  }

  ngOnInit(): void {

    this.newsForm = this.fb.group({
      title: [this.news.title || '', Validators.required],
      image: [this.news.image || '', Validators.required],
      date: [this.news.date ? moment(this.news.date).toDate() : '', Validators.required],
      content: [this.news.content || '', Validators.required],
    });

    if (this.news.image) {
      this.image = this.convertImage(this.news.image, 'images/');
    }
  }

  convertImage(base64string: string, path: string): string {
    if (base64string) {
      return `${environment.API_URL + path + base64string}`;
    }
    return '';
  }

  async handleUpload(event) {
    const file = event.target.files[0];
    const reader = new FileReader();
    const max_size = 1048576; // 1 Mb

    if (file.size > max_size) {
      Swal.fire({
        title: "Error",
        text: "Maximum size allowed is 1 Mb",
        icon: "error",
        showConfirmButton: false,
        timer: 4000,
      });
      return;
    }

    reader.readAsDataURL(file);
    reader.onload = async () => {
      if (typeof reader.result === 'string') {
        try {
          const compressed = reader.result;
          if (typeof compressed === 'string') {
            this.image = compressed;
            this.newsForm.patchValue({ image: compressed });
          } else {
            console.error('Error: Compressed image is not a string');
          }
        } catch (error) {
          console.error('Error compressing image:', error);
        }
      }
    };
  }

  removeUpload() {
    this.image = '';
    this.newsForm.patchValue({ image: '' });
  }


  onSave() {
    if (this.newsForm.valid) {
      const formValue = {
        ...this.newsForm.value,
        eventId: this.data.eventId,
        date: moment(this.newsForm.value.date).format('YYYY-MM-DD HH:mm:ss')
      };
      console.log('formValue', formValue)
      if (this.mode === 'create') {
        this.newsFeedService.create(formValue).subscribe(() => {
          this.dialogRef.close('success');
        });
      } else {
        this.newsFeedService.edit(this.news.id, formValue).subscribe(() => {
          this.dialogRef.close('success');
        });
      }
    }
  }

  onCancel(): void {
    this.dialogRef.close();
  }
}
