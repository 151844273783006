import { Component, Inject, OnInit } from "@angular/core";
import { Subscription, Observable } from "rxjs";
import { Store, select } from "@ngrx/store";
import { take, startWith, map } from "rxjs/operators";
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from "@angular/material";
import { GlobalState } from "src/app/store/states/global.state";
import {
  FormControl,
  FormGroup,
  FormBuilder,
  Validators,
} from "@angular/forms";

// Third libreries
import Swal from "sweetalert2";
import * as moment from "moment";

// Store
import { EventSearchAction } from "src/app/store/actions/event.actions";
import * as event from "src/app/store/selectors/event.selectors";

import { EventDetailsGetAllAction } from "src/app/store/actions/event-details.actions";
import * as eventDetails from "src/app/store/selectors/event-details.selectors";
import {EventService} from "src/app/services/event/event.service";


@Component({
  selector: "app-new-event",
  templateUrl: "./new-event.component.html",
  styleUrls: ["./new-event.component.css"],
})
export class NewEventComponent implements OnInit {
  dataSearch: any = [];
  dataEvents: any = [];
  disabledBtn: boolean = false;
  showNoData: boolean = true;
  searchEventFormGroup: FormGroup;

  // Event Details
  loadingEventDetails?: boolean = true;
  errorEventDetails?: boolean;
  public errorEventDetails$?: Observable<boolean>;

  // Store
  private subscription: Subscription = new Subscription();

  constructor(
    private _formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<NewEventComponent>,
    public dialog: MatDialog,
    public store: Store<GlobalState>,
    public _eventService : EventService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {


    this._eventService.getByUser().subscribe((res:any) => {
      if (res.valid && res.data.length > 0) {
      
        let data = res.data.map((element: any) => ({
          ...element,
          date: moment(element.date).format("YYYY-MM-DD"),
        }));
        this.dataSearch = this.dataEvents = data;

      } else {
        console.log('False->',res);
      }
    }); 


    // Set Forms
    this.searchEventFormGroup = this._formBuilder.group({
      eventName: ["", Validators.required],
      numberResult: [{ value: "", disabled: true }],
    });

    // Get All Events
    /*this.subscription.add(
      this.store
        .pipe(select(eventDetails.selectEventDetailsLoading))
        .subscribe((data) => {
          if (!data.loading) {
            this.store
              .pipe(select(eventDetails.selectEventDetailsResponse))
              .subscribe((response) => {
                if (response.valid && response.data.length > 0) {
                  let data = response.data.map((element: any) => ({
                    ...element,
                    date: moment(element.date).format("YYYY-MM-DD"),
                  }));
                  this.dataSearch = this.dataEvents = data;
                }
              });
          }
        })
    );*/
  }

  async searchEvent() {
    if (this.searchEventFormGroup.value.eventName.length) {
      this.store.dispatch(
        new EventSearchAction(<any>{
          data: this.searchEventFormGroup.value,
        })
      );

      const result = await this.store
        .select(event.selectEventLoading)
        .pipe(take(2))
        .toPromise();

      if (result && result.eventSearch && result.eventSearch.data.length) {
        this.dataSearch = result.eventSearch.data;
      } else {
        // No Data
        this.showNoData = false;
      }
    } else {
      this.dataSearch = this.dataEvents;
      this.showNoData = true;
    }
  }

  checkSearchData(event: any) {
    if (!event.target.value.length) {
      this.dataSearch = this.dataEvents;
      this.showNoData = true;
    }
  }

  duplicate(idEvent) {
    window.location.href = `/#/superadmin/registration/${idEvent}/true`;
  }

  onChangeLimitResult(event) {
    if (event.checked) {
      this.searchEventFormGroup.controls["numberResult"].enable();
    } else {
      this.searchEventFormGroup.controls["numberResult"].disable();
    }
  }

  create() {
    window.location.href = "/#/superadmin/registration";
  }
}
