import { Component, OnInit, ViewChild } from "@angular/core";
import { Subscription, Observable } from "rxjs";
import { take, startWith, map } from "rxjs/operators";
import { Store, select } from "@ngrx/store";
import { GlobalState } from "src/app/store/states/global.state";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import {
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  DateAdapter,
} from "@angular/material";
import { MomentDateAdapter } from "@angular/material-moment-adapter";
import { SharedService } from "src/app/services/service.index";
import { NewUserComponent } from "src/app/modals/new-user/new-user.component";
import { NewRolComponent } from "src/app/modals/new-rol/new-rol.component";

// Store
import * as roles from "src/app/store/selectors/roles.selectors";

import {
  RolesGetAllAction,
  RolesDeleteAction,
  RolesEnabledAction,
} from "src/app/store/actions/roles.actions";
import { RolesParams } from "src/app/models/roles/roles-params";

// Third libreries
import Swal from "sweetalert2";
import * as moment from "moment";

//Services
import { RolesService } from "src/app/services/service.index";

// Modals
import { MatDialog } from "@angular/material/dialog";

export const MY_FORMATS = {
  parse: {
    dateInput: "LL",
  },
  display: {
    dateInput: "DD-MM-YY",
    monthYearLabel: "YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "YYYY",
  },
};

@Component({
  selector: "app-roles",
  templateUrl: "./roles.component.html",
  styleUrls: ["./roles.component.css"],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class RolesComponent implements OnInit {
  // Actions Datatable
  @ViewChild(MatPaginator, { static: false })
  set paginator(value: MatPaginator) {
    if (this.dataSource) {
      this.dataSource.paginator = value;
    }
  }
  @ViewChild(MatSort, { static: false })
  set sort(value: MatSort) {
    if (this.dataSource) {
      this.dataSource.sort = value;
    }
  }

  // Datatables
  displayedColumns: string[] = ["rol", "enabled", "actions"];
  dataSource: MatTableDataSource<any>;

  dateFromValue: any;
  dateUntilValue: any;
  statues: any[] = [];

  // Roles
  loadingRoles?: boolean = true;
  errorRoles?: boolean;
  public errorRoles$?: Observable<boolean>;

  // Store
  private subscription: Subscription = new Subscription();

  // Others
  actionModal: any = {
    edit: "edit",
    new: "new",
  };

  originalData: any;
  searchIn: number;
  selectedStatues: any;
  filterValue: "";

  constructor(
    private _sharedService: SharedService,
    private _rolesService: RolesService,
    public store: Store<GlobalState>,
    public dialog: MatDialog
  ) {
    this._sharedService.changeTitile("Roles");
  }

  openDialog(actionModal: any, idRoles?: any): void {
    const dialogRef = this.dialog.open(NewUserComponent, {
      width: "50%",
      data: { idRoles, actionModal },
    });
    dialogRef.afterClosed().subscribe((result) => {
      console.log("The dialog was closed", result);
      if (result) {
        // update datatables
      }
    });
  }

  ngOnInit() {
    // Get Roles
    this.store.dispatch(new RolesGetAllAction(null));

    this.subscription.add(
      this.store.pipe(select(roles.selectRolesLoading)).subscribe((data) => {
        this.loadingRoles = data.loading;
      })
    );

    this.store.pipe(select(roles.selectRolesError)).subscribe((error) => {
      this.errorRoles = error;
    });

    // Get All Roles
    this.subscription.add(
      this.store.pipe(select(roles.selectRolesLoading)).subscribe((data) => {
        if (!data.loading) {
          this.store
            .pipe(select(roles.selectRolesResponse))
            .subscribe((response) => {
              if (response.valid && response.data.length > 0) {
                let data = response.data.map((element: any) => ({
                  ...element,
                  date: moment(element.dateCreated).format("YYYY-MM-DD"),
                }));
                this.dataSource = new MatTableDataSource(data);
                this.dataSource.paginator = this.paginator;
                this.dataSource.sort = this.sort;
                this.originalData = data;
              } else {
                this.dataSource = new MatTableDataSource([]);
              }
            });
        }
      })
    );
  }

  newRol() {
    const dialogRef = this.dialog.open(NewRolComponent, {
      width: "20%",
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        // After close
      }
    });
  }

  editRol(rol) {
    const dialogRef = this.dialog.open(NewRolComponent, {
      width: "20%",
      data: rol,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        // After close
      }
    });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  enabledConfirmation(idRol: any) {
    Swal.fire({
      title: "Are you sure?",
      text: "The rol will be shown to users again",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, enabled it!",
    }).then((result) => {
      if (result.value) {
        this.store.dispatch(
          new RolesEnabledAction(<RolesParams>{
            data: { idRol },
          })
        );
      }
    });
  }

  deleteConfirmation(idRol: any) {
    Swal.fire({
      title: "Are you sure?",
      text: "The rol will no longer be displayed to users",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, disabled it!",
    }).then((result) => {
      if (result.value) {
        this.store.dispatch(
          new RolesDeleteAction(<RolesParams>{
            data: { idRol },
          })
        );
      }
    });
  }
}
