import {
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  ComponentRef, NgZone,
  OnInit, Renderer, Renderer2,
  ViewChild,
  ViewContainerRef
} from "@angular/core";
import {
  FormControl,
  FormGroup,
  FormBuilder,
  Validators,
} from "@angular/forms";
import { C, COMMA, ENTER } from "@angular/cdk/keycodes";
import { Subscription, Observable } from "rxjs";
import { take, startWith, map } from "rxjs/operators";
import { Store, select } from "@ngrx/store";
import { GlobalState } from "src/app/store/states/global.state";
import { Router, ActivatedRoute } from "@angular/router";
import {MatAutocomplete, MatAutocompleteSelectedEvent, MatAutocompleteTrigger} from "@angular/material/autocomplete";
import {SharedService, FieldsService, NotificationService} from "src/app/services/service.index";
import {EnventTeams} from "src/app/services/teams/team.service";


// Third libreries
import Swal from "sweetalert2";
import { compressImage } from "../../../utils/tools.utils";
import { v4 as uuidv4 } from "uuid";

// Store
import * as event from "src/app/store/selectors/event.selectors";
import * as eventDetails from "src/app/store/selectors/event-details.selectors";
import * as typeEvent from "src/app/store/selectors/type-event.selectors";
import * as years from "src/app/store/selectors/years.selectors";
import * as type_division from "src/app/store/selectors/type_division.selectors";
import * as gender from "src/app/store/selectors/gender.selectors";
import * as roles from "src/app/store/selectors/roles.selectors";
import * as user from "src/app/store/selectors/user.selectors";
import * as staff from "src/app/store/selectors/staff.selectors";


import {
  EventGetByIdAction,
  EventSaveAction,
} from "src/app/store/actions/event.actions";
import { EventParams } from "src/app/models/event/event-params";

import { EventDetailsSaveAction } from "src/app/store/actions/event-details.actions";
import { EventDetailsParams } from "src/app/models/event-details/event-details-params";

import { StaffSaveAction } from "src/app/store/actions/staff.actions";
import { StaffParams } from "src/app/models/staff/staff-params";
import { MatStepper } from "@angular/material/stepper";
import {element} from "protractor";
import {DivisionComponent} from "../../../modals/division/division.component";
import {MatDialog} from "@angular/material/dialog";
import {EventDetails} from "../../../models/event-details/event-details";
import { environment } from "src/environments/environment";
import { CoreEnvironment } from "@angular/compiler/src/compiler_facade_interface";
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { TypeSkillService } from "src/app/services/type_skill/type_skill.service";
import { AgeRestric } from "src/app/services/ageRestric/ageRestric.service";
import { divisionStylesService } from "src/app/services/divisionStyles/divisionStyles.service";
import {NewStaffComponent} from "../../../modals/new-staff/new-staff.component";
import {newspaperSharp} from "ionicons/icons";
import {NewFieldComponent} from  "src/app/modals/new-field/new-field.component";
import DisconnectListener = NodeJS.DisconnectListener;
import {DiscountCouponsService} from "../../../services/discountCoupons/discountCoupons.service";
import {NewCouponComponent} from "../../../modals/new-coupon/new-coupon.component";


@Component({
  selector: "app-registration",
  templateUrl: "./registration.component.html",
  styleUrls: ["./registration.component.css"],
})
export class RegistrationComponent implements OnInit {

  step1Completed: boolean = false;
  step2Completed: boolean = false;
  step3Completed: boolean = false;
  step4Completed: boolean = false;
  step5Completed: boolean = false;
  step6Completed: boolean = false;

  idEvent = uuidv4();
  event: any;
  eventId: string = "";
  duplicate: boolean = false;
  userInputValue: string = '';
  fieldInputValue: string = '';


  @ViewChild("viewContainerRef", { read: ViewContainerRef, static: false }) vcr!: ViewContainerRef;
  //ref!: ComponentRef<DivisionComponent>

  @ViewChild("stepper", { static: false }) stepper!: MatStepper;
  @ViewChild("stepperV", { static: false }) stepperV!: MatStepper;



  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  thirdFormGroup: FormGroup;
  fourthFormGroup: FormGroup;
  fieldsFormGroup: FormGroup;

  typeEvent: any = [];
  years: any = [];
  type_division: any = [];
  type_skill: any =[];
  ageRestric: any =[];
  divisionStyles : any =[];
  event_edit:boolean = false;
  gender: any = [];
  roles: any = [];
  selectedRol: any[] = [];
  users: any = [];
  fields: any = [];
  coupons: any = [];
  waivers: any = [];
  ccbquestions: any = [];
  allUsers: any = [];
  allFields: any = [];
  eventNumericId:number = null;
  imagesEvent: any = [
    {
      eventLogo: "",
      eventSponsor: [],
    },
  ];

  imagesEventSend: any = [
    {
      eventLogo: "",
      eventSponsor: [],
    },
  ];



  description: string;
  vanuedescription: string;
  divisions: EventDetails[] = [];

  separatorKeysCodes: number[] = [ENTER, COMMA];
  userCtrl = new FormControl();
  fieldCtrl = new FormControl();
  filteredUsers: Observable<string[]>;
  filteredFields: Observable<string[]>;
  // Event
  loadingEvent?: boolean;
  errorEvent?: boolean;
  estateDivision:boolean = false;
  public errorEvent$?: Observable<boolean>;

  // Event Details
  loadingEventDetails?: boolean;
  errorEventDetails?: boolean;
  public errorEventDetails$?: Observable<boolean>;

  // Staff
  loadingStaff?: boolean;
  errorStaff?: boolean;
  public errorStaff$?: Observable<boolean>;

  private subscription: Subscription = new Subscription();

  // Status Save
  stepOne = false;
  stepTwo = false;
  stepThree = false;
  stepFour = false;

  // Seacrh User
  selectable = true;
  removable = true;
  addOnBlur = false;

  // Others
  toolbarCkeditor = {
    toolbar: [
      ["Bold", "Italic", "Underline", "Strike"],
      ["TextColor", "BGColor"],
      [
        "NumberedList",
        "BulletedList",
        "-",
        "Blockquote",
        "-",
        "JustifyLeft",
        "JustifyCenter",
        "JustifyRight",
        "JustifyBlock",
      ],
      ["Link", "Unlink"],
      ["Image", "Table", "Smiley"],
      ["Undo", "Redo"],
    ],
  };
  isMobile:boolean;
  fieldsEvent: any[] = [];
  fielsAll: any[] = [];

  is_edit: boolean = false;
  private listener: () => void;
  timeoutId: any;

  constructor(
    public store: Store<GlobalState>,
    public router: Router,
    public route: ActivatedRoute,
    private _formBuilder: FormBuilder,
    private _sharedService: SharedService,
    public dialog: MatDialog,
    private CFR: ComponentFactoryResolver,
    private breakpointObserver: BreakpointObserver,
    private _skillService: TypeSkillService,
    private _ageResctric: AgeRestric,
    private _divisionStyles: divisionStylesService,
    private _fieldsService: FieldsService,
    private _discountService: DiscountCouponsService,
    private cdr: ChangeDetectorRef,
    private ngZone: NgZone,
    private renderer: Renderer2,
    private notificationService: NotificationService


  ) {
    this.breakpointObserver.observe([
      Breakpoints.HandsetPortrait,
      Breakpoints.HandsetLandscape
    ]).subscribe(result => {
      this.isMobile = result.matches;
    });

    // Set Title
    this._sharedService.changeTitile("Registration");

    // Set Forms
    this.firstFormGroup = this._formBuilder.group({
      typeEvent: ["", Validators.required],
      name: ["", Validators.required],
      date_from: ["", Validators.required],
      date_to: ["", Validators.required],
      payment_end: ["", Validators.required],
      late_fee: [0, Validators.required]
    });

    this.secondFormGroup = this._formBuilder.group({});

    this.thirdFormGroup = this._formBuilder.group({});

    this.fourthFormGroup = this._formBuilder.group({
      fourthCtrl: ["", Validators.required],
    });

    this.fieldsFormGroup = this._formBuilder.group({});

    // Get Params

    this.route.params.subscribe(async (params) => {
      this.eventId = params.eventId;
      this.duplicate = /true/i.test(params.duplicate);

      await this.get_data_selects();

      //this.allFielsbyUser();

      if (this.eventId) {

        this.is_edit = true;


        // Get Event Data
        this.store.dispatch(
          new EventGetByIdAction(<any>{
            data: { idEvent: this.eventId },
          })
        );

        const result = await this.store
          .select(event.selectEventLoading)
          .pipe(take(2))
          .toPromise();


        if(!this.duplicate){
         this.idEvent = result.event.data.idEvent;
         this.eventNumericId = result.event.data.id;

         this.step1Completed = true;
         this.step2Completed = true;
         this.step3Completed = true;
         this.step4Completed = true;
         this.step5Completed = true;
         this.step6Completed = true;
        }




        if (result && result.event && result.event.data) {
          let eventData = result.event.data;
          console.log(this.adjustDateToTimezone(eventData.date_from));
          this.event_edit = true;
          this.firstFormGroup.setValue({
            typeEvent: eventData.typeEvent.id,
            name: eventData.name,
            date_from: this.adjustDateToTimezone(eventData.date_from),
            date_to: this.adjustDateToTimezone(eventData.date_to),
            payment_end: this.adjustDateToTimezone(eventData.payment_end),
            late_fee: eventData.late_fee,
          });

          this.divisions = [...eventData.divisions];

          this.users = [...eventData.staff];

          //Waivers

          this.waivers = eventData.waivers.map(waiver => ({ ...waiver }));
          this.ccbquestions = eventData.ccbquestions.map(ccbquestion => ({ ...ccbquestion }));

          eventData.roles.forEach(element => {
            this.selectedRol.push(element.rol);
          });

          if(eventData.images) {
            this.imagesEvent = JSON.parse(eventData.images);
            this.imagesEventSend = JSON.parse(eventData.images);
            // console.log('test!!!',this.imagesEvent);
            this.imagesEvent[0].eventLogo =this.convertImage(this.imagesEvent[0].eventLogo,'images/')
            for (let i = 0; i < this.imagesEvent[0].eventSponsor.length; i++) {
              this.imagesEvent[0].eventSponsor[i] = this.convertImage(  this.imagesEvent[0].eventSponsor[i] ,'images/')
            }
          }
          this.description = eventData.description;
          this.vanuedescription = eventData.vanuedescription;

          this.fourthFormGroup.setValue({
            fourthCtrl: true,
          });
        }

        this._fieldsService.getFielsdByEvent({ eventId: this.eventId }).subscribe((res: any) => {
          if (res.valid) {
            this.fields = res.data;
            this.fieldsEvent =   this.fieldsEvent.map(item => item.id);
          }
        });

        this._discountService.getByEvent({ eventId: this.idEvent }).subscribe((res: any) => {
          if (res.valid) {
            this.coupons = res.data;
          }
        });

        this._sharedService.changeTitile("Event Setup");
      }
    });

    // Search User
    this.filteredUsers = this.userCtrl.valueChanges.pipe(
      startWith(null),
      map((user: string | null) =>
        user ? this._filter(user) : this.allUsers.slice()
      )
    );


    // Search field
    this.filteredFields = this.fieldCtrl.valueChanges.pipe(
      startWith(null),
      map((user: string | null) =>
        user ? this._filterField(user) : this.allFields.slice()
      )
    );
  }


  async ngOnInit() {


    // Get Type Events
    this.subscription.add(
      this.store
        .pipe(select(typeEvent.selectTypeEventLoading))
        .subscribe((loading) => {
          if (!loading) {
            this.store
              .pipe(select(typeEvent.selectTypeEventResponse))
              .subscribe((response) => {
                if (response.valid) {
                  this.typeEvent = response.data;
                }
              });
          }
        })
    );

    // Get Roles
    this.subscription.add(
      this.store.pipe(select(roles.selectRolesLoading)).subscribe((data) => {
        if (!data.loading) {
          this.store
            .pipe(select(roles.selectRolesResponse))
            .subscribe((response) => {
              if (response.valid) {
                this.roles = response.data;
              }
            });
        }
      })
    );

    // Get User
    this.subscription.add(
      this.store.pipe(select(user.selectUserLoading)).subscribe((loading) => {
        if (!loading.valid) {
          this.store
            .pipe(select(user.selectUserResponse))
            .subscribe((response) => {
              if (response && response.valid) {
                this.allUsers = response.data;
              }
            });
        }
      })
    );


   this._fieldsService.getAllFieldsByUser().subscribe(
      (res: any) => {
        if (res.valid) {
          this.allFields =res.data;
        } else {
          console.log('False fields all', res);
        }
      },
      (error) => {
        console.error('Error fetching fields', error);
      }
    );


  this._skillService.getAllTypeSkill().subscribe((res:any)=>{
      if (res.valid) {
        this.type_skill = res.data;
      } else {
        console.log('False TypeSkill');
      }
    });

   this._ageResctric.getAllAgeRestric().subscribe((res:any)=>{
      if (res.valid ) {
        this.ageRestric = res.data;
      } else {
        console.log('False AgeRestric');
      }
    });
    this._divisionStyles.getAlldivisionStyles().subscribe((res:any)=>{
      if (res.valid ) {
        this.divisionStyles = res.data;

      } else {
        console.log('False AgeRestric');
      }
    });

    await this.route.params.subscribe(async (params) => {
      if(params.eventId){
        setTimeout(() => {
          console.log(document.querySelector('.event_detail'));
          this.listener = this.renderer.listen(document.querySelector('.event_detail'), 'change', (event) => {
            const target = event.target as HTMLInputElement;
            if (target.tagName === 'INPUT' || target.tagName === 'SELECT') {
              this.listenerFirstStep();
            }
          });
        }, 1000);
      }
    });
  }

  listenerFirstStep(){
    if(!this.eventNumericId)
      return false;


    this.saveEventDetails().then(() => {
      if(this.stepOne){
        this.notificationService.dismissAllToast();
        this.notificationService.showSuccess("Event has been saved", "Success");
      }
    });
  }


  listenerFirstStepDebounced() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }

    // Inicia un nuevo temporizador de 3 segundos
    this.timeoutId = setTimeout(() => {
      this.listenerFirstStep();
    }, 3000); // 3000 ms = 3 seconds
  }

  listenerSecondStep(){
    if(!this.eventNumericId)
      return false;


    this.saveDivisions().then(() => {
      if(this.stepTwo){
        this.notificationService.dismissAllToast();
        this.notificationService.showSuccess("Event has been saved", "Success");
      }
    });
  }

  listenerThirdStep(){
    if(!this.eventNumericId)
      return false;


    this.saveUsers().then(() => {
      if(this.stepThree){
        this.notificationService.dismissAllToast();
        this.notificationService.showSuccess("Event has been saved", "Success");
      }
    });
  }

  ngOnDestroy() {
    if (this.listener) {
      this.listener();
    }
  }

  convertImage(base64string: string, path:string) {
    if (base64string.length) {
      return   `${environment.API_URL+path+ base64string}`
    }
  }



  compareRoles(rol,selectedRol){
    return !!selectedRol.find(srol => srol.id == rol.id);
  }




  async get_data_selects(){
    try{
        // Get Years
        let prom1 = new Promise(res=>{
            this.subscription.add(
              this.store.pipe(select(years.selectYearsLoading)).subscribe((loading) => {
                if (!loading) {
                  this.store
                    .pipe(select(years.selectYearsResponse))
                    .subscribe((response) => {
                      if (response.valid) {
                        this.years = response.data;
                        res(null);
                      }
                    });
                }
              })
            );
          })
          let prom2 = new Promise(res=>{
            // Get Division
            this.subscription.add(
              this.store
                .pipe(select(type_division.selectTypeDivisionLoading))
                .subscribe((loading) => {
                  if (!loading) {
                    this.store
                      .pipe(select(type_division.selectTypeDivisionResponse))
                      .subscribe((response) => {
                        if (response.valid) {
                          this.type_division = response.data;
                          res(null);
                        }
                      });
                  }
                })
            );
          })
          let prom3 = new Promise(res=>{
            // Get Gender
            this.subscription.add(
              this.store
                .pipe(select(gender.selectGenderLoading))
                .subscribe((loading) => {
                  if (!loading) {
                    this.store
                      .pipe(select(gender.selectGenderResponse))
                      .subscribe((response) => {
                        if (response.valid) {
                          this.gender = response.data;
                          res(null);
                        }
                      });
                  }
                })
            );
          })

       return  await  Promise.all([prom1, prom2, prom3])
    }catch(e){
      throw e;
    }
  }

  editDivision(division){

    //console.log("this.type_division", this.type_division)
    const dialogRef = this.dialog.open(DivisionComponent, {
      width: "90%",
      height: "auto",
      data: {
        years: this.years,
        gender: this.gender,
        type_division: this.type_division,
        division: {...division,
          typeYears: this.years.find(item => item.id === division.typeYears.id),
          ageRestric: this.ageRestric.find(item => item.id === (division.ageRestric && division.ageRestric.id)) || null,
          typeSkill: this.type_skill.find(item => item.id === (division.typeSkill && division.typeSkill.id)) || null,
          typeDivision: this.type_division.find(item => item.id === (  division.typeDivision && division.typeDivision.id)) || null,
          genderRestriction: this.gender.find(item => item.id === division.genderRestriction.id),
          divisionStyles : this.divisionStyles.find(item => item.id ===  (division.divisionStyles && division.divisionStyles.id)) || null,
        },
        skill: this.type_skill,
        ageRestric: this.ageRestric,
        styles:  this.divisionStyles
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        const index = this.divisions.findIndex(d => d === division);
        this.divisions[index] = result;
        this.listenerSecondStep();
      }
    });
  }

  i = 0;
  addChild() {

    const dialogRef = this.dialog.open(DivisionComponent, {
      width: "90%",
      height: "auto",
      data: {
          years: this.years,
          gender: this.gender,
          type_division: this.type_division,
          division: {
            customize: '#ffffff',
          },
          isMobile:this.isMobile,
          skill: this.type_skill,
          ageRestric: this.ageRestric,
          styles:  this.divisionStyles,

      },

    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        //console.log("division", result);
          this.divisions.push(result);
          this.listenerSecondStep();
        //setTimeout(()=>window.location.href = `/#/superadmin/pools/${division_id}`,1000)
      }
    });
  }

  addWaiver(){
    this.waivers = [...this.waivers, {question: ""}];
  }
  addCCBQuestion(){
    this.ccbquestions = [...this.ccbquestions, {question: ""}];

  }

  removeWaiver(waiverToRemove: any) {
    const index = this.waivers.indexOf(waiverToRemove);

    if (index >= 0) {
      this.waivers.splice(index, 1);
      if(waiverToRemove.id){
        let deletedWaiver = { ...waiverToRemove, deleted: true };
        this.waivers.push(deletedWaiver);
      }
    }
    this.listenerFirstStep();
  }

  removeCCBQuestion(CCBQuestionToRemove: any) {
    const index = this.ccbquestions.indexOf(CCBQuestionToRemove);

    if (index >= 0) {
      this.ccbquestions.splice(index, 1);
      if(CCBQuestionToRemove.id){
        let deleteCCBQuestion = { ...CCBQuestionToRemove, deleted: true };
        this.ccbquestions.push(deleteCCBQuestion);
      }
    }
    this.listenerFirstStep();
  }

  custom_next($e,$step){
    if(this.divisions.length > 0 ){
      if(!this.isMobile){
        this.stepper.next();
      }else{
        this.stepperV.next();
      }
    }
  }

  // Select Users
  remove(data, indx, type): void {
    if (type == 0) {
      this.users.splice(indx, 1);
      this.listenerThirdStep();
    }
    if (type == 'coupon') {
      this.coupons.splice(indx, 1);
      this.listenerFirstStep();
    } else {
      this._fieldsService.validateFieldEvent({ eventId: this.eventId, fieldId: data.id }).subscribe(
        (res: any) => {
          if (res.valid) {
            if (!res.data) {
              this.fields = [...this.fields];
              this.fields.splice(indx, 1);
              this.listenerFirstStep();
            } else {
              setTimeout(() => {
                Swal.fire({
                  title: "Error",
                  text: "The field is being used in a game.",
                  icon: "error",
                  showConfirmButton: false,
                  timer: 2000,
                });
              }, 800);
            }
          }
        }
      );
    }

  }

  selected(event: MatAutocompleteSelectedEvent, autocompleteTrigger: MatAutocompleteTrigger): void {
    if (!this.users.some(user => user.user.idUser === event.option.value.idUser)) {
      const dialogRef = this.dialog.open(NewStaffComponent, {
        width: "90%",
        height: "auto",
        data: {
          user: event.option.value,
          roles: this.roles,
          selectedRoles: [],
        },

      });



      dialogRef.afterClosed().subscribe((result) => {
        if (result) {


          let newStaff = {staffRoles:result,user:event.option.value};
          this.users = [...this.users, newStaff];

          autocompleteTrigger.closePanel();

          this.userCtrl.setValue(null);

          const inputElement = document.getElementById("input_user_text") as HTMLInputElement;

          if (inputElement) {
            inputElement.value = null;
          }

          this.userInputValue = '';

          this.listenerThirdStep();

        }
      });
    }

  }

  editStaff(staff){
    const dialogRef = this.dialog.open(NewStaffComponent, {
      width: "90%",
      height: "auto",
      data: {
        user: staff.user,
        roles: this.roles,
        selectedRoles: staff.staffRoles,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        const index = this.users.indexOf(staff);

        if (index !== -1) {
          const updatedStaff = Object.assign({}, this.users[index], { staffRoles: result });

          this.users = [...this.users];
          this.users[index] = updatedStaff;
          this.listenerThirdStep();
        }
      }
    });
  }

  editCoupon(coupon){
    const dialogRef = this.dialog.open(NewCouponComponent, {
      width: "20%",
      height: "auto",
      data: {
        coupon: {...coupon},
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        const index = this.users.indexOf(coupon);

        if (index !== -1) {
          this.coupons[index] = result;
          this.listenerFirstStep();
        }
      }
    });
  }

  selectedField(event: MatAutocompleteSelectedEvent, autocompleteTrigger: MatAutocompleteTrigger): void {
    const fieldExists = this.fields.some(existingField => existingField.id === event.option.value.id);
    if (!fieldExists) {
      this.fields = [...this.fields];
      this.fields.push(event.option.value);
      autocompleteTrigger.closePanel();
      this.fieldCtrl.setValue(null);
      const inputElement = document.getElementById("input_field_text") as HTMLInputElement;
      if (inputElement) {
        inputElement.value = null;
      }
      this.listenerFirstStep();
     }else{
      const inputElement = document.getElementById("input_field_text") as HTMLInputElement;
      if (inputElement) {
        inputElement.value = null;
      }
     }
  }

  private _filter(value: string) {
    return this.allUsers.filter((user) =>
      user.username.toLowerCase().includes(value)
    );
  }

  private _filterField(value: string) {
    return this.allFields.filter((field) =>
      field.name.toLowerCase().includes(value)
    );
  }

  // Select Role
  selectRol(list) {
    this.selectedRol = list.selectedOptions.selected.map(item => item.value);
  }

  // Get Images
  async handleUpload(event, type) {


    // Type : 0 - Event / 1 - Sponsor
    const file = event.target.files[0];
    const reader = new FileReader();
    const max_size = 1048576;

    if (event.target.files[0].size > max_size) {

      setTimeout(() => {
        Swal.fire({
          title: "Error",
          text: "Maximum size allowed is 1 Mb",
          icon: "error",
          showConfirmButton: false,
          timer: 4000,
        });
      }, 800);


      return false;
    }



    reader.readAsDataURL(file);
    reader.onload = () => {
      switch (type) {
        case 0:

          compressImage(reader.result, 300, 300).then((compressed) => {
            this.imagesEvent[0].eventLogo = compressed;
            this.imagesEventSend[0].eventLogo =compressed;
            this.listenerFirstStep();
          });
          break;
        case 1:

          compressImage(reader.result, 200, 200).then((compressed) => {
            this.imagesEvent[0].eventSponsor.push(compressed);
            this.imagesEventSend[0].eventSponsor.push(compressed);
            this.listenerFirstStep();
          });
          break;
        default:
          break;
      }
    };
  }

  // Remove Images
  removeUpload(type, idx) {
    // Type : 0 - Event / 1 - Sponsor
    switch (type) {
      case 0:
        this.imagesEvent[0].eventLogo = "";
        this.imagesEventSend[0].eventLogo = "";
        this.listenerFirstStep();
        break;
      case 1:
        this.imagesEvent[0].eventSponsor.splice(idx, 1);
        this.imagesEventSend[0].eventSponsor.splice(idx, 1);
        this.listenerFirstStep();
        break;
      default:
        break;
    }
  }

  //save (edit) event details
  async saveEventDetails(){

    this.firstFormGroup.value.id = this.eventNumericId;

    this.store.dispatch(
      new EventSaveAction(<EventParams>{
        data: { ...this.firstFormGroup.value,
          idEvent: this.idEvent,
          coupons: this.coupons,
          waivers: this.waivers,
          ccbquestions: this.ccbquestions,
          images: JSON.stringify(this.imagesEventSend),
          description: this.description,
          vanuedescription: this.vanuedescription,
          fields: this.fields},
      })
    );

    const firstSetep = await this.store
      .select(event.selectEventLoading)
      .pipe(take(2))
      .toPromise();
    this.stepOne = firstSetep.event ? firstSetep.event.valid : false;
    this.loadingEvent = firstSetep.loading;

    this.store.pipe(select(event.selectEventError)).subscribe((error) => {
      this.errorEvent = error;
    });
  }

  async saveDivisions(){

      let divisions =  this.divisions.map((division) => {
        return {
          ...division,
          idEvent: this.idEvent
        }
      });

      this.store.dispatch(
        new EventDetailsSaveAction(<EventDetailsParams>{
          data: {
            ...this.secondFormGroup.value,
            idEvent: this.idEvent,
            divisions: divisions,
            is_edit:this.event_edit
          },
        })
      );

      const secondSetep = await this.store
        .select(eventDetails.selectEventDetailsLoading)
        .pipe(take(2))
        .toPromise();
      this.stepTwo = secondSetep.eventDetails
        ? secondSetep.eventDetails.valid
        : false;
      this.loadingEventDetails = secondSetep.loading;

      this.store
        .pipe(select(eventDetails.selectEventDetailsError))
        .subscribe((error) => {
          this.errorEventDetails = error;
        });

  }

  async saveUsers(){

    this.store.dispatch(
      new StaffSaveAction(<StaffParams>{
        data: {
          users: this.users,
          roles: this.selectedRol,
          idEvent: this.idEvent,
        },
      })
    );

    const thirdSetep = await this.store
      .select(staff.selectStaffLoading)
      .pipe(take(2))
      .toPromise();
    this.stepThree = thirdSetep.staff ? thirdSetep.staff.valid : false;
    this.loadingStaff = thirdSetep.loading;

    this.store.pipe(select(staff.selectStaffError)).subscribe((error) => {
      this.errorStaff = error;
    });

  }

  // Save - All Step
  async seveAllStep() {
    // Save - First Step

    this.estateDivision = false;
    for (const registro of this.divisions) {
      const registroFormGroup = this._formBuilder.group({
        division_name: [registro.division_name, Validators.required],
        limit_player: [registro.limit_player, Validators.required],
        typeDivision: [registro.typeDivision, Validators.required],
        typeYears: [registro.typeYears, Validators.required],
        price: [registro.price, Validators.required],
        price_end: [registro.price_end, Validators.required],
        cupon_code: [registro.cupon_code],
        age_restriction: [registro.age_restriction],
        genderRestriction: [registro.genderRestriction, Validators.required],
        team_size: [registro.team_size, Validators.required],
        typeSkill: [registro.typeSkill, Validators.required],
        ageRestric: [registro.ageRestric, Validators.required],
        divisionStyles: [registro.divisionStyles, Validators.required],
        customize: [registro.customize,Validators.required]
      });

      // validate the  FormGroup
      if (registroFormGroup.valid === false) {
        this.estateDivision = true;
        const  errorItem = Object.keys(registroFormGroup.controls).filter(control => registroFormGroup.controls[control].invalid);
        //console.log('Error item',errorItem);
      }
    }


    if (!this.estateDivision){

    if (!this.stepOne) {
      if(this.event_edit){
        this.firstFormGroup.value.id = this.eventNumericId;
      }

    if(this.duplicate){
      delete this.firstFormGroup.value.id;
    }

      this.store.dispatch(
        new EventSaveAction(<EventParams>{
            data: { ...this.firstFormGroup.value,
            idEvent: this.idEvent,
            coupons: this.coupons,
            waivers: this.waivers,
            ccbquestions: this.ccbquestions,
            images: JSON.stringify(this.imagesEventSend),
            description: this.description,
            vanuedescription: this.vanuedescription,
            duplicate: this.duplicate,
            fields: this.fields},
        })
      );



      const firstSetep = await this.store
        .select(event.selectEventLoading)
        .pipe(take(2))
        .toPromise();
      this.stepOne = firstSetep.event ? firstSetep.event.valid : false;
      this.loadingEvent = firstSetep.loading;

      this.store.pipe(select(event.selectEventError)).subscribe((error) => {
        this.errorEvent = error;
      });
    }

    // Save - Second Step
    if (!this.stepTwo && this.stepOne) {
      let divisions =  this.divisions.map((division) => {
            return {
              ...division,
              idEvent: this.idEvent
            }
      });


      if(this.duplicate){
          divisions.forEach(division => {
            delete division.id
            });
      }

      /*if(this.event_edit && divisions.length > 0){
        this.secondFormGroup.patchValue(divisions[0]);
        this.secondFormGroup.value.id = divisions[0].id;
        divisions = divisions.filter((_e,i)=>i!==0);
      }*/
     /* console.log({
        ...this.secondFormGroup.value,
        images: JSON.stringify(this.imagesEvent),
        description: "Hello, world!",
        idEvent: this.idEvent,
        divisions: divisions,
        is_edit:this.event_edit
      })*/

      this.store.dispatch(
        new EventDetailsSaveAction(<EventDetailsParams>{
          data: {
            ...this.secondFormGroup.value,
            idEvent: this.idEvent,
            divisions: divisions,
            is_edit:this.event_edit
          },
        })
      );
      const secondSetep = await this.store
        .select(eventDetails.selectEventDetailsLoading)
        .pipe(take(2))
        .toPromise();
      this.stepTwo = secondSetep.eventDetails
        ? secondSetep.eventDetails.valid
        : false;
      this.loadingEventDetails = secondSetep.loading;

      this.store
        .pipe(select(eventDetails.selectEventDetailsError))
        .subscribe((error) => {
          this.errorEventDetails = error;
        });
    }

    // Save - Third Step
    if (!this.stepThree && this.stepOne && this.stepTwo) {
      this.store.dispatch(
        new StaffSaveAction(<StaffParams>{
          data: {
            users: this.users,
            roles: this.selectedRol,
            idEvent: this.idEvent,
          },
        })
      );

      const thirdSetep = await this.store
        .select(staff.selectStaffLoading)
        .pipe(take(2))
        .toPromise();
      this.stepThree = thirdSetep.staff ? thirdSetep.staff.valid : false;
      this.loadingStaff = thirdSetep.loading;

      this.store.pipe(select(staff.selectStaffError)).subscribe((error) => {
        this.errorStaff = error;
      });
    }

    // Save - Fourth Step
    if (!this.stepFour && this.stepOne && this.stepTwo && this.stepThree) {
      this.stepFour = true;
      setTimeout(() => {
        window.location.href = "/#/superadmin/events";
      }, 2000);
    }

    // Check all save
    if (this.stepFour && this.stepOne && this.stepTwo && this.stepThree) {
      setTimeout(() => {
        Swal.fire({
          title: "Success",
          text: "Save Event",
          icon: "success",
          showConfirmButton: false,
          timer: 2000,
        });
      }, 800);
    } else {
      setTimeout(() => {
        Swal.fire({
          title: "Error",
          text: "Save Event",
          icon: "error",
          showConfirmButton: false,
          timer: 2000,
        });
      }, 800);
    }
  }else{
    setTimeout(() => {
      Swal.fire({
        title: "Error",
        text: "Save Division, incomplete data",
        icon: "error",
        showConfirmButton: false,
        timer: 2000,
      });
    }, 800);
  }
  }

allFielsbyUser(){

  this._fieldsService.getAllFieldsByUser().subscribe(
    (res: any) => {
      if (res.valid) {
        this.allFields=res.data;
      } else {
        console.log('False fields all', res);
      }
    },
    (error) => {
      console.error('Error fetching fields', error);
    }
  );
}

  createFields() {
    const dialogRef = this.dialog.open(NewFieldComponent, {
      width: "20%",
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.allFielsbyUser();
      }
    });
  }

  createCoupon() {
    const dialogRef = this.dialog.open(NewCouponComponent, {
      width: "20%",
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.coupons.push(result);
        this.listenerFirstStep();
      }
    });
  }

  adjustDateToTimezone(dateString: string): Date {
      const date = new Date(dateString);
      return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());
  }

  checkStep1Completion() {
    this.step1Completed = true;
    if(!this.isMobile){
      setTimeout(() => {
        this.stepper.next();
      },150);
    }else{
      setTimeout(() => {
        this.stepperV.next();
      },150);
    }
  }
  checkStep2Completion() {
    this.step2Completed = true;
    if(!this.isMobile){
      setTimeout(() => {
        this.stepper.next();
      },150);
    }else{
      setTimeout(() => {
        this.stepperV.next();
      },150);
    }
  }
  checkStep3Completion() {
    this.step3Completed = true;
    if(!this.isMobile){
      setTimeout(() => {
        this.stepper.next();
      },150);

    }else{
      setTimeout(() => {
        this.stepperV.next();
      },150);
    }
  }
  checkStep4Completion() {
    this.step4Completed = true;
    if(!this.isMobile){
      setTimeout(() => {
        this.stepper.next();
      },150);
    }else{
      setTimeout(() => {
        this.stepperV.next();
      },150);
    }
  }
  checkStep5Completion() {
    this.step5Completed = true;
    if(!this.isMobile){
      setTimeout(() => {
        this.stepper.next();
      },150);
    }else{
      setTimeout(() => {
        this.stepperV.next();
      },150);
    }
  }
  checkStep6Completion() {
    this.step6Completed = true;
    if(!this.isMobile){
      setTimeout(() => {
        this.stepper.next();
      },150);
    }else{
      setTimeout(() => {
        this.stepperV.next();
      },150);
    }
  }

}
