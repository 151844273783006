import { Component, OnInit, ViewChild } from "@angular/core";
import { Subscription, Observable } from "rxjs";
import { take, startWith, map } from "rxjs/operators";
import { Store, select } from "@ngrx/store";
import { GlobalState } from "src/app/store/states/global.state";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import {
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  DateAdapter,
} from "@angular/material";
import { MomentDateAdapter } from "@angular/material-moment-adapter";
import {EventService, SharedService} from "src/app/services/service.index";
import { NewUserComponent } from "src/app/modals/new-user/new-user.component";
import { NewEventComponent } from "src/app/modals/new-event/new-event.component";
import { MassEditDivisionsComponent } from "src/app/modals/mass-edit-divisions/mass-edit-divisions.component";

// Store
import * as event from "src/app/store/selectors/event.selectors";
import * as eventDetails from "src/app/store/selectors/event-details.selectors";

import { EventDetailsGetAllAction } from "src/app/store/actions/event-details.actions";
import {
  EventDeleteAction,
  EventEnabledAction,
} from "src/app/store/actions/event.actions";
import { EventParams } from "src/app/models/event/event-params";

// Third libreries
import Swal from "sweetalert2";
import * as moment from "moment";

//Services
import { EventDetailsService } from "src/app/services/service.index";

// Modals
import { MatDialog } from "@angular/material/dialog";
import { NewBracketsComponent } from "src/app/modals/new-brackets/new-brackets.component";

export const MY_FORMATS = {
  parse: {
    dateInput: "LL",
  },
  display: {
    dateInput: "DD-MM-YY",
    monthYearLabel: "YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "YYYY",
  },
};

@Component({
  selector: "app-events",
  templateUrl: "./events.component.html",
  styleUrls: ["./events.component.css"],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class EventsComponent implements OnInit {
  // Actions Datatable
  @ViewChild(MatPaginator, { static: false })
  set paginator(value: MatPaginator) {
    if (this.dataSource) {
      this.dataSource.paginator = value;
    }
  }
  @ViewChild(MatSort, { static: false })
  set sort(value: MatSort) {
    if (this.dataSource) {
      this.dataSource.sort = value;
    }
  }

  // Datatables
  displayedColumns: string[] = [
    "name",
    "address",
    "date_from",
    "date_to",
    "total_team",
    "enabled",
    "actions",
  ];
  dataSource: MatTableDataSource<any>;

  dateFromValue: any;
  dateUntilValue: any;
  statues: any[] = [];

  // Event Details
  loadingEventDetails?: boolean = true;
  errorEventDetails?: boolean;
  public errorEventDetails$?: Observable<boolean>;

  // Store
  private subscription: Subscription = new Subscription();

  // Others
  actionModal: any = {
    edit: "edit",
    new: "new"
  };

  originalData: any;
  searchIn: number;
  selectedStatues: any;
  filterValue: "";

  constructor(
    private _sharedService: SharedService,
    private _eventDetailsService: EventDetailsService,
    private _eventService: EventService,
    public store: Store<GlobalState>,
    public dialog: MatDialog
  ) {
    this._sharedService.changeTitile("Events");
  }

  openDialog(actionModal: any, idEvent?: any): void {
    const dialogRef = this.dialog.open(NewUserComponent, {
      width: "50%",
      data: { idEvent, actionModal },
    });
    dialogRef.afterClosed().subscribe((result) => {
      console.log("The dialog was closed", result);
      if (result) {
        // update datatables
      }
    });
  }

  ngOnInit() {
    this._eventService.getByUser().subscribe((res:any)=>{
      if (res.valid && res.data.length > 0) {
        let data = res.data.map((element: any) => ({
          ...element,
          date: moment(element.date).format("YYYY-MM-DD"),
        }));
        console.log();
        this.dataSource = new MatTableDataSource(res.data);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.originalData = data;
      } else {
        this.dataSource = new MatTableDataSource([]);
      }
    })
  }

  newEvent() {
    const dialogRef = this.dialog.open(NewEventComponent, {
      width: "50%",
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        // After close
      }
    });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  eventEdit(eventId: any) {
    window.location.href = `/#/superadmin/edit-event/${eventId}`;
  }

  enabledConfirmation(idEvent: any) {
    Swal.fire({
      title: "Are you sure?",
      text: "The event will be shown to users again",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, enabled it!",
    }).then((result) => {
      if (result.value) {
        this.store.dispatch(
          new EventEnabledAction(<EventParams>{
            data: { idEvent },
          })
        );
      }
    });
  }

  deleteConfirmation(idEvent: any) {
    Swal.fire({
      title: "Are you sure?",
      text: "The event will no longer be displayed to users",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, disabled it!",
    }).then((result) => {
      if (result.value) {
        this.store.dispatch(
          new EventDeleteAction(<EventParams>{
            data: { idEvent },
          })
        );
      }
    });
  }

  allDivisionsEdit (idEvent:any){
    const dialogRef = this.dialog.open(MassEditDivisionsComponent, {
      width: "280px",
      data: {idEvent}
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {        
        setTimeout(() => {
          Swal.fire({
            title: "Success",
            text: "Save Event",
            icon: "success",
            showConfirmButton: false,
            timer: 2000,
          });
        }, 800);
      }
    });
  }
  goCalendar(eventId: any){        
    window.location.href = `/#/superadmin/event/${eventId}/calendar`;    
  }
}
